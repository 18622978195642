export const gameSettingsConstants = {
  GAME_SETTINGS_LIST_REQUEST: 'GAME_SETTINGS_LIST_REQUEST',
  GAME_SETTINGS_LIST_SUCCESS: 'GAME_SETTINGS_LIST_SUCCESS',
  GAME_SETTINGS_LIST_FAILURE: 'GAME_SETTINGS_LIST_FAILURE',
  GAME_SETTINGS_LIST_CHANGED: 'GAME_SETTINGS_LIST_CHANGED',
  GAME_SETTINGS_LIST_PAGINATION: 'GAME_SETTINGS_LIST_PAGINATION',

  GAME_SETTINGS_LOAD_REQUEST: 'GAME_SETTINGS_LOAD_REQUEST',
  GAME_SETTINGS_LOAD_SUCCESS: 'GAME_SETTINGS_LOAD_SUCCESS',
  GAME_SETTINGS_LOAD_FAILURE: 'GAME_SETTINGS_LOAD_FAILURE',

  GAME_SETTINGS_SAVE_REQUEST: 'GAME_SETTINGS_SAVE_REQUEST',
  GAME_SETTINGS_SAVE_SUCCESS: 'GAME_SETTINGS_SAVE_SUCCESS',
  GAME_SETTINGS_SAVE_FAILURE: 'GAME_SETTINGS_SAVE_FAILURE',
};
