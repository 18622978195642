import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import { useLocation } from "react-router";

import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import Topbar from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { withTranslation } from "react-i18next";

import newtonLogo from '../../../../../src/assets/images/newton_logo.svg';

import { hasMainMenuAccess } from "./MenuAccess";

const routesSelections = [
  ["/dashboard"],
  ["/tenants", "/locations", "/slots", "/slots-demo", "/slots-offline", "/bulk-actions", "/events", "/entity-groups"],
  ["/jackpots", "/jackpot-history", "/jackpot-sources", "/display-channels"],
  ["/reports/slot-games", "/reports/daily-reports", "/reports/monthly-reports", "/reports/payment-transactions", "/reports/collector-checkpoints"],
  ["/advanced/smibs", "/advanced/checkpoints", "/advanced/timelog", "/advanced/tasks", "/advanced/aggregation-rules"],
  ["/acl/users", "/acl/user-groups", "/acl/permissions", "/acl/roles", "/acl/entity-groups", "/acl/rules", "/acl/api-tokens"],
  ["/admin/columns-configurations", "/admin/general-settings"],
  ["/operations/remote-cashout"],
  ["/notifications/list", "/notifications/subscriptions"],
  ["/player-exclusion/players", "/player-exclusion/self-exclusions", "/player-exclusion/player-scans", "/player-exclusion/blacklists"],
  ["/games-registry/game-profiles", "/games-registry/game-mixes", "/games-registry/games", "/games-registry/machine-types", "/games-registry/manufacturers", "/games-registry/protocols", "/games-registry/game-settings"]
];

function Header(props) {
  const location = useLocation();

  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
    };
  }, [uiService]);

  const [activeTab, setActiveTab] = React.useState(0);
  const handleTabClick = (index) => () => {
    setActiveTab(index);
  };

  React.useEffect(() => {
    let path_name = location.pathname.split(/[?#]/)[0];
    for (let index = 0; index < routesSelections.length; index++) {
      for (let i = 0; i < routesSelections[index].length; i++) {
        let temp_path = path_name.split('/');
        if (temp_path[temp_path.length - 1] === '') {
          temp_path.pop();
        }
        if (temp_path[temp_path.length - 1].length === 24) {
          temp_path.splice(temp_path.length - 1, 1);
          path_name = temp_path.join('/');
        }
        if (path_name.indexOf(routesSelections[index][i]) > -1 && routesSelections[index][i] === path_name) {
          setActiveTab(index);
          return;
        }
      }
    }
  }, [location]);

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Top*/}
        <div className="header-top">
          {/*begin::Container*/}
          <div
            className={`${layoutProps.headerContainerClasses}`}
          >
            {/* begin::Left */}
            <div className="d-none main-logo d-lg-flex align-items-center">
              {/* begin::Header Logo */}
              <Link to="/access" className="mr-5">
                <img
                  className="max-h-35px max-w-130px mt-5"
                  alt="Logo"
                  src={newtonLogo}
                />
              </Link>

              <ul className="header-tabs nav align-self-end font-size-lg" role="tablist">
                {hasMainMenuAccess("DASHBOARD") && (
                  <li className="nav-item">
                    <a onClick={handleTabClick(0)} className={`nav-link py-3 px-2 ${activeTab === 0 ? 'active' : ''}`}>{props.t("Home")}</a>
                  </li>
                )}
                {(hasMainMenuAccess("MONITORING")) && (
                  <li className="nav-item">
                    <a onClick={handleTabClick(1)} className={`nav-link py-3 px-2 ${activeTab === 1 ? 'active' : ''}`}>{props.t("Monitoring")}</a>
                  </li>
                )}
                {hasMainMenuAccess("JACKPOTS") && (
                  <li className="nav-item">
                    <a onClick={handleTabClick(2)} className={`nav-link py-3 px-2 ${activeTab === 2 ? 'active' : ''}`}>{props.t("Jackpots")}</a>
                  </li>
                )}
                {hasMainMenuAccess("REPORTS") && (
                  <li className="nav-item">
                    <a onClick={handleTabClick(3)} className={`nav-link py-3 px-2 ${activeTab === 3 ? 'active' : ''}`}>{props.t("Reports")}</a>
                  </li>
                )}
                {hasMainMenuAccess("ADVANCED") && (
                  <li className="nav-item">
                    <a onClick={handleTabClick(4)} className={`nav-link py-3 px-2 ${activeTab === 4 ? 'active' : ''}`}>{props.t("Advanced")}</a>
                  </li>
                )}
                {hasMainMenuAccess("ACL") && (
                  <li className="nav-item">
                    <a onClick={handleTabClick(5)} className={`nav-link py-3 px-2 ${activeTab === 5 ? 'active' : ''}`}>{props.t("Access Control List")}</a>
                  </li>
                )}
                {hasMainMenuAccess("ADMIN") && (
                  <li className="nav-item">
                    <a onClick={handleTabClick(6)} className={`nav-link py-3 px-2 ${activeTab === 6 ? 'active' : ''}`}>{props.t("Admin")}</a>
                  </li>
                )}
                {hasMainMenuAccess("OPERATIONS") && (
                  <li className="nav-item">
                    <a onClick={handleTabClick(7)} className={`nav-link py-3 px-2 ${activeTab === 7 ? 'active' : ''}`}>{props.t("Operations")}</a>
                  </li>
                )}
                {hasMainMenuAccess("NOTIFICATIONS") && (
                  <li className="nav-item">
                    <a onClick={handleTabClick(8)} className={`nav-link py-3 px-2 ${activeTab === 8 ? 'active' : ''}`}>{props.t("Notifications")}</a>
                  </li>
                )}
                {hasMainMenuAccess("PLAYER_EXCLUSION") && (
                  <li className="nav-item">
                    <a onClick={handleTabClick(9)} className={`nav-link py-3 px-2 ${activeTab === 9 ? 'active' : ''}`}>{props.t("Player Exclusion")}</a>
                  </li>
                )}
                {hasMainMenuAccess("GAMES_REGISTRY") && (
                  <li className="nav-item">
                    <a onClick={handleTabClick(10)} className={`nav-link py-3 px-2 ${activeTab === 10 ? 'active' : ''}`}>{props.t("Games Registry")}</a>
                  </li>
                )}
              </ul>
              {/* end::Header Logo */}

            </div>
            {/* end::Left */}

            {/*begin::Topbar*/}
            <Topbar />
            {/*end::Topbar*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Top*/}

        {/*begin::Bottom*/}
        <div className="header-bottom">
          <div className="container-fluid">
            {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper activeMenu={activeTab} tabClick={handleTabClick} />}
          </div>
        </div>
        {/*end::Bottom*/}
      </div>
      {/*end::Header*/}
    </>
  );
}

export default withTranslation()(Header);
